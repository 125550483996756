.cookieConsent {
  position: fixed;
  bottom: 0;
  z-index: 9999;
  width: 100%;
  padding: 15px 0;
  background: rgb(39, 41, 45);
  color: #fff;
  font-size: 12px;
}

.cookieConsentContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cookieConsentContent a {
  transition: all 0.25s ease;
}

.cookieConsentContent a,
.cookieConsentContent a:hover {
  color: #fff;
  text-decoration: underline;
}

.cookieConsentContent > div {
  margin-right: 20px;
}

@media only screen and (min-width: 768px) {
  .cookieConsentContent a:hover {
    opacity: 0.8;
  }

  .cookieConsentContent a:active {
    opacity: 0.5;
  }
}
